import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../Store";
import { allMessagesStore } from "../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { deviceSizeStore } from "../../Store/Slices/socket/DeviceSizeSlice";
import { StyledChatUIBoxWrap } from "../Styles/StyledScreens";
import AppChatScreenWrapper from "../appComponents/UtilsComponents/AppChatScreenWrapper";
import useMessagesData from "../hooks/useMessagesData";
import { PreviewType } from "../utils/Enum";
import AppInputHandler from "./AppInputHandler";
import Header from "./Header";
import WidgetCopyright from "./WidgetCopyright";
import AppChatLoadingScreen from "../appComponents/UtilsComponents/AppChatLoadingScreen";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
  isFullScreen?: boolean;
}

const MainUI: React.FC<Props> = ({ isFullScreen }) => {
  const { chatOpened, widgetPreviewType } = useAppSelector(botInfoStore);
  const { height, width } = useAppSelector(deviceSizeStore);
  const { sessionId, botLanguage, allMessages } =
    useAppSelector(allMessagesStore);
  //custom hooks
  const { getNewMessages, getHistoryMessages, loading, getNewMessagesForDirectLivechat } = useMessagesData();

  const { botConfig } = useAppSelector(useBotConfigStore);

  const conversationLimit = useMemo(
    () => botConfig?.config?.conversation,
    [botConfig]
  );
  const directLiveChatEnabled = useMemo(
    () => botConfig?.chatbot?.hasOwnProperty('bot_enabled') ? !botConfig.chatbot.bot_enabled : false,
    [botConfig]
  );

  // ********************************************************

  // ─── REQUEST DATA EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************



  const getMessages = () => {

    const isOldVisitor = sessionId?.length > 0 && widgetPreviewType !== PreviewType.getaWidgetPreview;

    if (isOldVisitor) { 
      // console.warn("previous session found");
      getHistoryMessages({session_id: sessionId, language: botLanguage});
    }

    else if (directLiveChatEnabled) {
      // console.warn("direct chat");
      getNewMessagesForDirectLivechat({session_id: null, language: botLanguage, bot_enabled: false});
    }

    else {
      //get new messgaes
      getNewMessages({session_id: null, language: botLanguage});
    }

  };

  useEffect(() => {

    if (!chatOpened) return;
    if (!conversationLimit?.enabled) return;
    if (allMessages?.length > 0) return;
    
    getMessages();

  }, [chatOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledChatUIBoxWrap
      chatopened={chatOpened ? 1 : 0}
      devicewidth={width}
      deviceheight={height - 78}
      isFullScreen={isFullScreen}
    >
      <Header />
      <AppChatLoadingScreen loading={loading} />
      <AppChatScreenWrapper />
      <AppInputHandler />
      <WidgetCopyright />
    </StyledChatUIBoxWrap>
  );
};

export default MainUI;
